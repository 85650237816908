:root {
  --navbackgrounddark: #2B2182;
  --navbackgroundlight: #2A7EA2;
  --linkshover: #B18183;

}

.NavbarItems {
  background: linear-gradient(
    90deg,
    var(--navbackgrounddark) 0%,
    var(--navbackgroundlight) 100%
  );
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  width: 100%;
  text-align: left;
  letter-spacing: 0.1rem;
}

.fa-bug {
  margin-left: 0.5rem;
  font-size: 2rem;
  transition: all 0.2s ease-in-out;
}

.fa-bug:hover {
  color: var(--linkshover);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-menu

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: var(--linkshover);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 800px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .nav-menu.active {
    background: #6668f4;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #7577fa;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}
