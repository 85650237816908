:root {
  --primary: #3acbf7;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-in-out;
  background: #fff;
  color: #6568f4;
}

.btn--primary {
  background-color: var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-in-out;
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: medium;
  color: #fff;
}

.btn--large {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: large;
  color: #fff;
}
