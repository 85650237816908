:root {
  --sitebackground: #EAEAEB;
  --headings: #292768;
}

.App {
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

body {
  background-color: var(--sitebackground);
}

h1, h2, h3, h4
{
  color: var(--headings);
}